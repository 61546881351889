.blog__single{
    margin: 120px 0;
    max-width: 980px;

    &__date{
        text-align: center;
        color: $font_medium;
    }

    .main__image{
        width: 100%;
        height: auto;
        max-height: 500px;
        object-fit: contain;
    }

    .single__page__navigation{
        justify-content: space-between;

        @media (max-width: $small_screen){
            justify-content: space-around;
        }
    }
    .nav-box{
        margin-top: 80px;
    }
    .nav-box a{
        display: flex;
        position: relative;
        max-width: 350px;
        justify-content: flex-start;
        text-decoration: none;
        transition: .25s ease-out;

        @media (max-width: $mobile){
            max-width: 140px;
        }



        .ico{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -30px;
        }
        .thumb__wrapper{
            width: 100px;

            @media (max-width: $mobile){
                display: none;
            }
        }
        .desc__wrapper{
            width: 50%;
            padding-left: 15px;
            color: $font_dark;
            font-size: $p_standard;

            @media (max-width: $mobile){
                width: 100%;
            }
        }
        .nav-box-title{
            font-size: $header_superSmall;
            margin-bottom: 5px;
        }

    }

    .nav-box.next{
        a{
            justify-content: flex-end;
        }
        .thumb__wrapper{
            order: 2;

        }
        .desc__wrapper{
            order: 1;
            text-align: right;
            padding-right: 15px;
            padding-left: 0;
        }

        .ico{
            right: -30px;
            left: auto;
            transform: rotate(180deg) translateY(50%);

        }
    }
}

