.navigation{
    ul{
        list-style: none;
    }
    .menu-item{
        a{
            //color: $font_light;
            color: #292d48;
            text-decoration: none;
            font-size: $top_navigation;
            @media (max-width: $mobile){
                color: $font_light;
            }
        }
    }

    &__horizontal{
        .menu-item{
            display: inline-block;
            padding: 5px;
            margin: 0 15px;

            @media(max-width: $big_screen){
                margin: 0 7px;
            }
        }
    }
    &__vertical{
        .menu-item{
            display: block;

        }
    }

    .menu-item-has-children{
        position: relative;
        padding-right: 5px;

        // &::after{
        //     position: absolute;
        //     right: -15px;
        //     top: 50%;
        //     transform: translateY(-50%);
        //     content: '';
        //     display: inline-block;
        //     background-image: url(../img/ico/arrow_white.svg);
        //     background-size: contain;
        //     background-position: center;
        //     background-repeat: no-repeat;
        //     width: 13px;
        //     height: 15px;
        // }

        &:hover{
            .sub-menu{
                height: 230px;
                //opacity: 1;
                display: inline-block;

                @media(max-width: $medium_screen){
                    height: 200px;
                }
            }
        }
    }

    .sub-menu{
        height: 0;
        opacity: 0;
        display: none;
        position: absolute;
        background-color: white;
        border-top: 4px solid $sec_color;
        padding: 10px 13px;
        left: 0;
        top: 100%;
        transition: height .3s ease-out;
        overflow: hidden;

        .menu-item{
            display: block;
            position: relative;
            z-index: 200;
        }
    }


}

.main__navigation{
    padding-top: 18px;
    padding-left: 20px;

    .sub-menu__hover{
        background-color: $sec_color;
        position: absolute;
        width: 100%;
        height: 24px;
        left: 0;
        top: -24px;
        z-index: 1;
        transition: .2s ease-out;
    }


}
@media (max-width: $small_screen){
    #primary-menu{
        display: none;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%) ;

        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        height: 100vh;
        padding: 10% 0;




        .menu-item{
            display: block;
            width: 100%;
            text-align: center;
        }
        a{
            font-size: 4.5vw;
        }

        .sub-menu{
            display: none;
        }

        .menu-item-has-children{
            &::after{
                display: none;
            }
        }

    }


}
@media (max-width: $mobile){
    #primary-menu{
        padding: 21% 0;

        a{
            font-size: 6.5vw;
        }
    }
}

#footer-menu{
    list-style: none;

    .menu-item{
        margin: 10px 0;
    }

    a{
        color: $font_light;
        text-decoration: none;
        font-size: $footer_items;

    }
    @media (max-width: $mobile){
        text-align: center;
        margin: 40px 0 ;
    }
}