.site__header{
    //background-color: $main_color;
    padding: 20px 0;
    padding-bottom: 80px;
    position: relative;
    padding-bottom: 0;
    //box-shadow: 0 15px 60px 10px rgba($color: #000000, $alpha: .3);
    overflow-y: visible;
    @media (max-width: $mobile){
        padding-bottom: 0;
    }
}
.top__header__wrapper{
    position: relative;
    z-index: 905;


    @media (max-width: $mobile){
        justify-content: space-around;
    }

}
.main__logo{
    max-height: 100px;
    width: auto;
    -moz-transition: 1s;
    -ms-transition: 1s;
    -o-transition: 1s;
    -webkit-transition: 1s;
    transition: 1s;
    @media(max-width: $medium_screen){
        max-height: 70px;
    }
}

.top__contact{
    padding-top: 22px;
    padding-left: 30px;
    display: none;

    @media (max-width: $medium_screen){
        padding-left: 15px;
    }
    @media (max-width: $small_screen){
        padding-left: 65px;
        padding-top: 28px;

        .ico{
            top: -3px;
        }
    }

    @media (max-width: $mobile){
        padding: 20px 0;
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: 10px;
    }

    ul{
        list-style: none;
    }
    &__item{
        display: inline-block;
        padding-left: 30px;
        position: relative;
        margin-right: 30px;

        @media (max-width: $medium_screen){
            margin-right: 10px;
        }

    }
    a{
        text-decoration: none;
        //color: $font_light;
        color: #292d48;
        font-size: $top_navigation;
    }

    .ico{
        position: absolute;
        left: 0;
    }

    .ico__email{
        width: 25px;
        height: 25px;
        top: -3px;
        left: -3px;
        @media (max-width: $small_screen){

            top: -9px;

        }
    }

}

.main__slider{
    margin-top: 90px;
    padding: 40px 60px;
    max-width: 1400px;
    @media(max-width: $mobile){
        margin-top: 20px;
        padding: 40px 0px;
    }


    &.swiper-container{
        z-index: 800;
    }


    h1,p{
        //color: $font_light;
        color: #292d48;
    }

    .slider__item{
        padding: 0 80px;
        box-sizing: border-box;

        @media(max-width: $mobile){
            padding: 0 15px;
        }

        &__desc{
            width: 100%;
            padding: 30px 0;

            p{
                font-size: $header_small;
                margin: 15px 0;
            }

            h1, h2{
                position: relative;
                padding-bottom: 28px;
                margin: 15px 0;
                font-size: $header_superBig;
                //color: $font_light;
                color: #292d48;

                // &::after{
                //     display: block;
                //     content: '';
                //     width: 180px;
                //     height: 14px;
                //     position: absolute;
                //     bottom: 0;
                //     background-repeat: repeat-x;
                //     background-position: 0 0;
                //     background-size: contain;
                //     background-image: url(../img/ico/underline_long.svg);
                // }
            }
        }
    }
}

.header__image{
    position: absolute;
    top: 0;
    right: 0;
    height: 75%;
    width: 50%;
    min-width: 1000px;
    background-position: right top;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 10;
    @media(min-width: $big_screen){
        height: 520px;
    }
    @media(max-width: $big_screen){
        height: 480px;
    }
    @media(max-width: $medium_screen){
        right: -300px;
    }
    @media (max-width: $mobile){
        display: none;
    }

}

.small-header{
    height: 380px;
}

.smaller-header{
    height: 340px;
}

.swiper-pagination{
    @media (max-width: $mobile){
        display: none;
    }
}
.swiper-button-prev-main, .swiper-button-next-main{
    @media(max-width: $mobile){
        display: none;
    }
}

.page__header{
    //color: $font_light;
    color: #292d48;
    padding: 80px 9%;
    position: relative;
    z-index: 800;
    padding-left: 80px;
    left: 200px;

    @media(max-width: $big_screen){
        left: 0;
        padding-left: 40px;
    }

    @media(max-width: $mobile){
        padding: 80px 5px;
    }
}

.sticky{
    position: fixed;
    background-color: #fff;
    width: 100%;
    max-width: none;
    padding-left: 13%;
    border-bottom: 1px solid #292d48;
    top: 0;
    @media(max-width: $big_screen){
        padding-left: 33px;
    }
}

.sticky > a > .main__logo{
    margin-bottom: 10px;
    height: 55px;
    margin-top: 10px;
}