/* Titillium */

@font-face {
	font-family: "Titillium";
	font-style: normal;
	font-weight: normal;
	src: url("fonts/Titillium-Regular.otf") format("truetype");
}
@font-face {
	font-family: "Titillium";
	font-style: italic;
	font-weight: 100 ;
	src: url("fonts/Titillium-LightItalic.otf") format("truetype");
}
@font-face {
	font-family: "Titillium";
	font-style: normal;
	font-weight: 100 ;
	src: url("fonts/Titillium-Light.otf") format("truetype");
}
@font-face {
	font-family: "Titillium";
	font-style: italic;
	font-weight: bold;
	src: url("fonts/Titillium-BoldItalic.otf") format("truetype");
}
@font-face {
	font-family: "Titillium";
	font-style: normal;
	font-weight: bold;
	src: url("fonts/Titillium-Bold.otf") format("truetype");
}
@font-face {
	font-family: "Titillium";
	font-style: normal;
	font-weight: 900;
	src: url("fonts/Titillium-Black.otf") format("truetype");
}