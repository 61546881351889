
.main__navigation{
    .menu-item{
        a{
            position: relative;

            // &::after{
            //     content: '';
            //     display: inline-block;
            //     position: absolute;
            //     bottom: -12px;
            //     left: 50%;
            //     transform: translateX(-50%);
            //     width: 50px;
            //     height: 8px;
            //     background-size: contain;
            //     background-position: center;
            //     background-repeat: no-repeat;
            // }
            // &:hover::after{
            //     background-image: url(../img/ico/underline.svg);
            // }
        }
        a:hover{
            font-weight: 600;
        }
    }
    .current-menu-item a{
        position: relative;
        font-weight: 600;
        // &::after{
        //     content: '';
        //     display: inline-block;
        //     position: absolute;
        //     bottom: -12px;
        //     left: 50%;
        //     transform: translateX(-50%);
        //     width: 50px;
        //     height: 8px;
        //     background-size: contain;
        //     background-position: center;
        //     background-repeat: no-repeat;
        //     background-image: url(../img/ico/underline.svg);
        // }
    }
}

.sub-menu{

    .menu-item{
        a{
            color: $font_dark;
            transition: .3s ease-out;
            &:hover{
                color: $font_light;
            }
            &::after{
                display: none;
            }
        }

    }
}

.top__contact{
    a{
        transition: .3s ease-out;
        &:hover{
            font-weight: 600;
        }
    }
}

.btn{
    transition: .3s ease-out;

    &:hover{
        background-color: $sec_color;
        color: $font_light;
    }
}

.swiper-pagination-bullet-active{
    background-color: $sec_color;
}

#footer-menu{
    a:hover{
        color: $sec_color;
    }
}

.home__offer .slide__wrapper:hover{
    border-color: transparent;
    box-shadow: 0 0 45px 5px rgba($color: #000000, $alpha: .12);
    .home__offer-slide__title::after{
        width: 110px;
    }
}

.blog .blog__item{
    &:hover{
        transform: scale(1.02);
    }
}

.nav-box a:hover{
    transform: scale(1.05);
}