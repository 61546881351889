.news{

    &__wrapper{
        justify-content: space-between;

    }
    &__thumb{
        width: 35%;
        height: 100%;
        max-height: 200px;
        background-image: url(../img/thumb_examp.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 30px;
        box-shadow: 0 0 20px 5px rgba($color: #000000, $alpha: .2);
    }

    &__desc{
        width: 65%;
        padding:  5px 20px;

        h3{
            margin: 0;
        }

        .btn{
            padding: 6px 10px;
        }

    }

    &__item{
        width: 42%;
        margin: 40px 0;

        @media(max-width: $small_screen){
            width: 50%;
        }
        @media(max-width: $mobile){
            width: 80%;
            @include centerX;
        }
    }

    &__date{
        margin: 0;
        margin-bottom: 10px;
        color: $font_medium;
    }

    &__excerpt{
        margin: 5px 0;
        margin-bottom: 10px;
    }

}