.blog{
    &__item{
        margin: 20px 0;
        align-items: center;


        &__thumb{
            width: 150px;
            height: 150px;

            @media(max-width: $mobile){
                display: none;
            }
        }

        &__desc{
            border-bottom: 1px solid $font_dark;
            padding: 40px 0;
            margin: 0 40px;
            flex: 1 1 50%;

        }

        .news__title{
            color: $font_dark;
            margin: 10px 0;
            margin-top: 0;
        }
    }

    a{
        text-decoration: none;
        transition: .25s ease-out;

    }
}