#map{
    height: 650px;
}

.contact__form__wrapper{
    margin-top: 0;
    margin-bottom: 0;
    z-index: 900;
    position: relative;

    .grid{
        padding-bottom: 60px;
        &__empty{
            width: 15%;
            @media (max-width: $small_screen){
                display: none;
            }
        }
        &__form{
            width: 60%;
            @media (max-width: $small_screen){
                width: 65%;
            }
            @media (max-width: $mobile){
                width: 100%;
            }
        }
        &__info{
            width: 25%;
            padding: 0 40px;
            background-color: $main_color;
            color: $font_light;
            @media (max-width: $small_screen){
                width: 35%;
            }
            @media (max-width: $mobile){
                width: 100%;
            }
        }
        a, li, p{
            color: $font_light;
            padding-left: 30px;
            margin: 3px 0;
            font-size: $p_standard;
            text-decoration: none;
        }
        h4{
            margin-bottom: 10px;
            margin-top: 7px;
        }
    }
}

.grid__form{
    .section__title{
        background-color: $main_color;
        color: $font_light;
        padding: 30px 60px;
        margin-top: -110px;

        @media (max-width: $big_screen){
            margin-top: -100px;
        }
        @media (max-width: $medium_screen){
            margin-top: -95px;
        }
    }
    form{
        padding: 0 40px;
        max-width: 1000px;
    }
}

.page-id-47{
    .site__footer{
        margin-top: 0;
    }
    .contact__form__wrapper{
        .input__wrapper{
            margin: 25px 0;

        }
    }
}

