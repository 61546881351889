$header_superBig: 3.938rem; // 63px
$header_standard: 2.75rem; // 44px
$header_small: 1.688rem; // 27px
$header_superSmall: 1.125rem; //18px


$top_navigation: 1.125rem; //18px

$button_standard: 0.9375rem; // 15px

$p_standard: 0.875rem; //14px
$p_big: 0.9375rem; //15px

$footer_header: 1.375rem; //22px
$footer_items: 1.125rem; //18px



$main_color: #292D48;
$sec_color: #D62542;

$font_light: #fff;
$font_medium: #8c8c8c;
$font_dark: #292D48;


/* Media Q */
$big_screen: 1440px;

$medium_screen: 1200px;

$small_screen: 980px;

$mobile: 600px;