.site__footer{
    background-color: $main_color;
    margin-top: 80px;
    padding: 40px;
    font-size: $footer_items;
    color: $font_light;
    box-shadow: 0 -10px 50px 10px rgba($color: #000000, $alpha: .2);

    .flex{
        justify-content: space-around;
        @media (max-width: $mobile){
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    h3{
        font-size: $footer_header;
        @media (max-width: $mobile){
            text-align: center;
        }
    }

    p{
        color: $font_light;
    }

    .footer__item{
        max-width: 25%;

        @media (max-width: $mobile){
            width: 90%;
            max-width: 90%;
        }
    }
    img{
        @media (max-width: $mobile){
            margin-bottom: 25px;
        }
    }
}

.footer__contact{
    &__wrapper.flex{
        justify-content: flex-start;

        a{
            padding-left: 30px;
            margin-bottom: 15px;
            color: $font_light;
            text-decoration: none;
        }

        p{
            margin: 2px 0;
            padding-left: 30px;
            &:nth-child(3){
                margin-bottom: 10px;
            }
        }
    }
    &__header{
        margin-top: 0;
        margin-bottom: 5px;
        width: 100%;
        font-family: "Titillium";



        span{
            margin-right: 12px;
        }
    }

}