.home__reviews{
    background-color: $main_color;
    box-shadow: 0 0 60px 20px rgba($color: #000000, $alpha: .2);
    position: relative;

    .flex{
        padding: 40px;

        @media(max-width: $small_screen){
            padding: 40px 0px;
        }
    }

    .section__title{
        color: $font_light;
        text-align: center;
    }

    &__wrapper{
        width: 35%;
        padding: 0 30px;

        @media(max-width: $small_screen){
            width: 50%;
        }
        @media(max-width: $mobile){
            width: 100%;
        }
    }
    &__about{
        width: 37%;
        border-left: 2px solid $sec_color;
        padding: 0 40px;
        color: $font_light;

        .section__title{
            text-align: left;

            @media(max-width: $mobile){
                text-align: center;
            }
        }

        p{
            margin-bottom: 40px;
            color: $font_light;
        }

        @media(max-width: $medium_screen){
            width: 42%;
        }
        @media(max-width: $small_screen){
            width: 50%;
        }
        @media(max-width: $mobile){
            width: 95%;
            border-left: 0;
            border-top: 2px solid $sec_color;
            @include centerX;
        }

    }

    &__img{
        width: 27%;
        min-width: 520px;
        background-image: url(../img/person_image_1.png);
        background-size: contain;
        background-position: left bottom;
        background-repeat: no-repeat;
        position: absolute;
        height: 120%;
        bottom: 0;
        right: 0;

        @media(max-width: $big_screen){
            min-width: 440px;
        }
        @media(max-width: $medium_screen){
            min-width: 350px;
        }
        @media(max-width: $small_screen){
            display: none;
        }
    }

    &__slider{
        overflow: hidden;
        padding: 0 30px;
        max-width: 400px;
        @include centerX;
    }



    .swiper-button-prev, .swiper-button-next{
        top: 50px;
    }
}

.offer__slide{
    &__thumb{
        width: 100px;
        height: 100px;
        background-size: cover;
        background-position: center;
        border-radius: 50%;
        @include centerX;
    }
    &__desc{
        color: $font_light;
        text-align: center;
        font-size: $p_big;
        @include centerX;
        p{
            color: $font_light;
        }
    }

    .meta__desc{
        color: $font_medium;
    }

}