.home__offer{
    padding-bottom: 95px;
    //padding-top: 50px;

    .section__title{
        padding-left: 30px;
        @media(max-width: $mobile){
            padding-left: 0!important;
        }
    }

    .swiper-wrapper{
        flex-wrap: wrap;
    }

    &__wrapper{
        overflow: hidden;
        padding: 0 0px;
    }

    &__thumb{
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 120px;

        @media(max-width: $small_screen){
            height: 80px;
        }

    }
    &__desc{
        p{
            line-height: 1.6;
            color: $font_medium;
        }
    }
    .swiper-slide{
        width: 25%;
        height: 505px;
        padding: 20px;
        padding-top: 30px;
        padding-right: 30px;
        padding-left: 30px;
        @media (max-width: $medium_screen){
            width: 50%;   
        }
        @media (max-width: $mobile){
            width: 100%;   
        }
    }
    .slide__wrapper{
        border: 1px solid $font_medium;
        border-radius: 15px;
        padding: 15px;
        text-align: center;
        box-shadow: 0 0 0px 0px rgba($color: #000000, $alpha: .12);
        transition: .2s ease-out;
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
      height: 100%;
    }


    .swiper-button-prev, .swiper-button-next{
        right: auto;
        left: 50%;
        bottom: 0;
        top: auto;
    }
    .swiper-button{
        &-prev{
            transform: translateX(-100%);
        }
        &-next{
            transform: translateX(100%) rotate(180deg);
        }
    }

    &-slide__title{
        position: relative;

        &::after{
            content: '';
            @include centerX;
            position: absolute;
            bottom: -10px;
            width: 50px;
            height: 2px;
            background-color: $sec_color;
            transition: .2s ease-out;

        }
    }
    .btn__wrapper{
        width: 100%;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }


}