.menu-toggle{
    display: none;
    width:35px;
    height:35px;
    position:relative;
    cursor:pointer;
    background:transparent;
    border-radius:5px;
    border: 1px solid white;
    color: $sec_color;
    text-indent: 35px;
    font-weight: bold;
    letter-spacing: 1px;
    outline: none;
    z-index: 1000;

    &:hover, &:focus {
        border: 1px solid $sec_color;
    }
    span {
        display:block;
        background:$sec_color;
        border-radius:2px;
      }

    @media(max-width: $small_screen){
        display: inline-block;

    }
}
#hamburger {
    position:absolute;
    height:100%;
    width:100%;
    top: 0;
    left: 0;
    transition: .25s ease-out;

    span {
      width:20px;
      height:3px;
      position:relative;
      top: 4px;
      left:7px;
      margin:4px 0;
      transition: .25s ease-out;

      &:nth-child(1){
        transition-delay:.5s;
      }
      &:nth-child(2){
        transition-delay:.625s;
      }
      &:nth-child(3){
        transition-delay:.75s;
      }
    }
  }
  #cross {
    position:absolute;
    height:100%;
    width:100%;
    transform:rotate(45deg);
    top: 0;
    left: 0;
    transition: .25s ease-out;

    span{
      transition: .25s ease-out;
      &:nth-child(1){
        height:0%;
        width:3px;
        position:absolute;
        top:10%;
        left:15px;
        transition-delay:0s;
      }
      &:nth-child(2){
        width:0%;
        height:3px;
        position:absolute;
        left:10%;
        top:15px;
        transition-delay:.25s;
      }
    }
  }


.menu-toggle.open {
    border-color: $sec_color;
  #hamburger {
    span {
      width:0%;
      &:nth-child(1){
        transition-delay:0s;
      }
      &:nth-child(2){
        transition-delay:.125s;
      }
      &:nth-child(3){
        transition-delay:.25s;
      }
    }
  }
  #cross {
    span {
      &:nth-child(1){
        height:80%;
        transition-delay:.625s;
      }
    }
    span {
      &:nth-child(2){
        width:80%;
        transition-delay:.375s;
      }
    }
  }
}





.btn{
    text-decoration: none;
    border: 1px solid $sec_color;
    border-radius: 10px;
    padding: 10px 15px;
    font-size: $button_standard;
    display: inline-block;
    background-color: transparent;
}


.swiper-button-next, .swiper-container-rtl .swiper-button-prev{
    background-image: url(../img/ico/arrow.svg);
    transform: rotate(180deg);
    height: 30px;
    background-size: contain;
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next{
    background-image: url(../img/ico/arrow.svg);
    height: 30px;
    background-size: contain;
}
.swiper-pagination-bullet{
    background-color: $font_medium;
    opacity: 1;
    width: 10px;
    height: 10px;
}