.reviews__page{
    margin: 200px 0;
    //margin-bottom: 0;
    @media (max-width: $mobile){
        margin: 80px 0;
    }


    .swiper-container{
        padding: 100px 0;
    }

    .review__item{
        align-items: center;

        &__desc{
            width: 50%;
            padding: 0 40px;
            position: relative;
            top: -20px;

            @media(max-width: $mobile){
                width: 100%;
            }
        }

        &__thumb{
            height: 400px;
            width: 400px;
            background-size: cover;
            border-radius: 50%;
            background-position: center;
            background-repeat: no-repeat;
            @include centerX;

            @media (max-width: $medium_screen){
                height: 250px;
                width: 250px;
            }
            @media(max-width: $mobile){
                display: none;
            }

            &__wrapper{
                width: 50%;
                position: relative;
                top: -80px;
            }
        }

        &__name{
            color: $sec_color;
            margin: 5px 0;
        }
        &__info{
            color: $font_light;
            margin: 5px 0;
            margin-bottom: 25px;
        }
        &__content{
            color: $font_light;
        }
    }

    .reviews__wrapper{
        background-color: $main_color;
        box-shadow: inset 0 -80px 0 0 white, 0 -25px 45px 0 rgba($color: #000000, $alpha: .15);

        @media(max-width: $mobile){
            box-shadow: inset 0 -20px 0 0 white, 0 -25px 45px 0 rgba($color: #000000, $alpha: .15);
        }
    }
    .reviews__slider__wrapper{
        position: relative;
        overflow: hidden;
        padding-top: 100px;
        padding-bottom: 0;
        top: -60px;
    }

    .header__wrapper{
        width: 60%;
        position: relative;
        display: flex;
        padding-left: 50px;
        @media(max-width: $big_screen){
            padding-left: 0;
        }
        @media (max-width: $mobile){
            width: 100%;
        }

        .review__navigation{
            position: relative;
            width: 190px;
            margin: 0 30px;
            align-items: center;
            display: flex;
            justify-content: center;

            @media (max-width: $small_screen){
                margin: 0;
            }
        }

    }

    &.reviews__business{
        .review__item__desc{
            order: 2;
        }
        .review__item__thumb__wrapper{
            order: 1;
        }

        .header__wrapper{
            left: 100%;
            transform: translateX(-100%);
        }
    }

}

.my-pagination{
    position: relative;
    top: -5px;
    display: inline-block;
    width: auto;
    font-size: $header_small;
    font-family: "Titillium";

    @media (max-width: $mobile){
        display: none;
    }
}
span.underline__pagination{
    border-bottom: 1px solid $sec_color;
    width: 50px;
    display: inline-block;
    margin: 0 5px;
}
.swiper-pagination-total{
    font-size: $header_small;
}