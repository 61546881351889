.wpcf7{
    .btn{
        color: $sec_color;
        padding: 7px 17px;
        position: relative;
        left: 100%;
        transform: translateX(-100%);
        cursor: pointer;
    }
    .wpcf7-text{
        border: 0;
        border-bottom: 1px solid rgba($color: #000000, $alpha: .4);
        padding: 5px 10px;
        outline: none;
        transition: .25s ease-out;

        &:active, &:focus{
            border-bottom-color: $sec_color;
            outline: none;
        }

        &::placeholder{
            font-size: 1rem;
            opacity: .35;
            font-family: "Titillium";
        }
    }

    textarea{
        padding: 5px 10px;
        outline: none;
        resize: none;
        width: 100%;

        &:active, &:focus{
            border-color: $sec_color;
            outline: none;
        }
        &::placeholder{
            font-size: 1rem;
            opacity: .35;
            font-family: "Titillium";
        }
    }

    .input__wrapper{
        display: flex;
        margin: 10px 0;
        justify-content: space-between;

        .wpcf7-form-control-wrap{
            display: block;
            width: 45%;

        }
        input{
            width: 100%;
        }
    }

    .textarea__wrapper{
        .wpcf7-form-control-wrap{
            width: 100%;

        }
    }

    select{
        border: 0;
        border-bottom: 1px solid rgba($color: #000000, $alpha: .4);
        width: 100%;
        padding: 5px 10px;
        outline: none;

    }
    option{
        font-size: 1rem;
        color: rgba($color: #000000, $alpha: .35);
        font-family: "Titillium";

        &:checked{
            color: rgba($color: #000000, $alpha: 1);
        }

    }

}