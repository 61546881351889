html{
    font-size: 16px;
    overflow-x: hidden;
    @media (max-width: $big_screen ){
        font-size: 14px;
    }
    @media (max-width: $medium_screen){
        font-size: 12px;
    }
    @media (max-width: $small_screen){
        font-size: 12px;

    }
    &.hidden{
        overflow: hidden;
    }
}

body{
    overflow-x: hidden;
    color: $font_dark;
    font-family: "Titillium";
}
* {

    box-sizing:border-box;
}

h1,h2,h3,h4{
    font-family: "Titillium", serif;
}
h1{
    font-size: $header_superBig;
}
h2, .section__title{
    font-size: $header_standard;
}
.section__title{
    font-weight: normal;

    @media(max-width: $mobile){
        text-align: center;
    }
}

h3{
    font-size: $header_superSmall;
}

#menu-item-75{
  display: none !important;
}

.page__header__small{
    font-size: $header_small;
    width: 600px;
    @media(max-width: $mobile){
      width: 100%;
    }
}

a,p{
    font-family: "Titillium", sans-serif;
}
p{
    font-size: $header_superSmall;
    line-height: 1.4;
    color: $font_dark;
}
a{
    color: $sec_color;
}

ul, ol{
    padding-left: 0;
    margin: 0;
}

::selection{
    background-color: $sec_color;
    color: $font_light;
}

.wrapper{
    max-width: 1450px;
    width: 95%;
    @include centerX;
}

.flex{
    display: flex;
    flex-wrap: wrap;

}

section{
    margin: 80px 0;
  @media(max-width: $mobile){
    margin: 0;
  }
}

.meta{
    color: $font_medium;
}

.mobile_bg{
    position: fixed;
    background-color: $main_color;
    border: 2px solid $sec_color;
    border-radius: 50%;
    opacity: 0;
    width: 5px;
    height: 5px;
    z-index: 800;
    transform: translateX(-50%) translateY(-30%);
    transition:opacity .2s ease-out, .5s ease-out;

    &.open{
        width: 2000px;
        height: 2000px;
        opacity: 1;
    }
}

.quo{
   position: relative;
   display: inline-block;
   height: 10px;
   width: 20px;

   &::after{
       position: absolute;
       top: 0;
       left: 0;
       content: '';
        height: 15px;
        width: 15px;
        background-size: contain;
        background-position: top center;
        background-image: url(../img/ico/quo.svg);
        background-repeat: no-repeat;
   }

   &.end{
       transform: rotate(180deg);
   }

   @media (max-width: $medium_screen){
       &::after{
        height: 12px;
        width: 12px;
       }
   }
}

.aboutMe__photo2{
  display: none;
  @media(max-width: $mobile){
    display: block;
  }
}

.aboutMe__photo2 img{
  border-radius: 20px;
  width: 90%;
  height: auto;
}

.mobileFooter{
  @media(max-width: $mobile){
    display: none;
  }
}