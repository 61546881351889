.ico{
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition:.25s ease-in-out;

    &__phone{
        background-image: url(../img/ico/ico-phone_white.svg);
        &--color{
            background-image: url(../img/ico/ico-phone-color.svg);
        }
    }
    &__email{
        background-image: url(../img/ico/ico-email_white.svg);
        &--color{
            background-image: url(../img/ico/ico-email-color.svg);
        }
    }
    &__adress{
        background-image: url(../img/ico/ico-address.svg);
        &--white{
            background-image: url(../img/ico/ico-address_white.svg);
        }
    }
    &__arrow{
        background-image: url(../img/ico/arrow.svg);
    }
}