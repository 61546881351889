.aboutMe{
    position: relative;
    margin-top: 60px;

    @media(max-width: $mobile){
        margin-top: 60px;
    }

    &__bg{
        position: absolute;
        bottom: 50px;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 270px 0 0 white;
        background-color: $main_color;
        z-index: 1;

        @media(max-width: $big_screen){
            box-shadow: inset 0 230px 0 0 white;
        }

        @media (max-width: $small_screen){
            bottom: 0;
            box-shadow: inset 0 200px 0 0 white;
        }

        @media (max-width: $mobile){
            box-shadow: inset 0 180px 0 0 white;
        }
    }
    .wrapper{
        z-index: 100;
        justify-content: space-between;
    }

    ol{
        list-style: none;
        margin-bottom: 60px;

        li{
            margin: 5px 0;
        }
    }

    h2{
        margin-bottom: 15px;
        color: $font_dark;

        @media(max-width: $mobile){
            font-size: 28px;
        }
    }

    &__photo{
        width: 50%;

        img{
            border-radius: 20px;
            width: 90%;
            height: auto;
        }

        @media (max-width: $mobile){
            display: none;
        }
    }
    &__desc{
        width: 50%;
        color: $font_light;
        line-height: 1.4;

        p{
            color: $font_light;
            font-size: $header_superSmall;
        }
        @media (max-width: $mobile){
            width: 100%;
        }
    }

    &__courses{
        &__title{
            margin-top: 60px;
        }

        &__list{
            list-style: none;
            columns: 2;

            li{
                margin: 5px 0;
                position: relative;
                padding-left: 15px;

                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: $sec_color;
                }
            }
        }
    }
}

.target{
    &__info{
        width: 100%;
        justify-content: space-between;
        align-items: center;
        //background-color: #fff;

        @media(max-width: $mobile){
            width: 100%;
            margin: 40px 0;
        }

        &__item{
            width: 33%;
            text-align: center;
            padding: 0 5px;
        }

        p{
            margin: 7px 0;
            font-size: $header_superSmall;
        }

        .count{
            //color: $sec_color;
            font-size: $footer_header;
        }

        span{
            display: inline-block;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 100%;
            height: 90px;

        }
        .item__costumers span{
            background-image: url(../img/ico/ico_person.svg);
        }
        .item__work span{
            background-image: url(../img/ico/ico_raport.svg);
        }
        .item__exp span{
            background-image: url(../img/ico/ico_date.svg);
        }

    }

    &__desc{
        width: 45%;
        padding-right: 25px;

        @media(max-width: $mobile){
            width: 100%;
        }
    }
}

.awards{
    background-color: #f3f4f5;
    padding: 40px 0;
    padding-bottom: 0;

    .section__title{
        color: $sec_color;
    }



    &__year__wrapper{
        position: relative;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
    }
    &__year{
        @include centerX;
        position: absolute;
        font-size: 15px;
        width: 55px;
        height: 55px;
        display: inline-block;
        border: 1px solid $sec_color;
        border-radius: 50%;
        text-align: center;
        line-height: 3.6;
        background-color: #f3f4f5;
        z-index: 200;
        font-family: "Titillium";
    }

    &__item{
        &__wrapper{
            padding: 40px;
            display: inline-block;
            align-items: flex-start;
            width: 50%;
            text-align: right;
            border-right: 1px solid $sec_color;

            &.right{
                align-self: flex-end;
                border-left: 1px solid $sec_color;
                border-right: 0;
                text-align: left;
                position: relative;
                left: -1px;

                .award__title{
                    &::before{
                        left: -45px;
                        right: auto;
                    }
                }
                .awards__thumb{
                    left: 0;
                    transform: translateX(0);
                }
            }
        }
    }

    &__thumb{
        position: relative;
        max-width: 350px;
        left: 45%;
        @media(max-width: $big_screen){
            left: 38%;
        }
        //transform: translateX(-100%);

        img{
            position: relative;
            width: 100%;
            height: auto;
        }
    }
    .award__title{
        width: 100%;
        position: relative;
        font-size: $header_superSmall;

        &::before{
            content: '';
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $sec_color;
            top: 50%;
            transform: translateY(-50%);
            right: -45px;
        }
    }
}

.costumers{
    overflow: hidden;
    &__wrapper{
        position: relative;
        .costumers__slider{
            .costumer__item{
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }
    img{
        max-height: 150px;
        width: auto;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        max-width: 65%;
    }
}