.faq{
    &__section{
        margin: 10px 0;
    }

    &__section__header{
        background-color: $main_color;
        border-radius: 10px;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        h3{
            color: $font_light;
            font-size: $footer_header;
            padding: 20px 10px;
            width: 40%;
            flex: 1 1 40%;
        }

        .ico{
            padding: 0 50px;
            transform: rotate(-90deg);
        }
    }

    &__thumb{
        width: 130px;
        height: 80px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        @media(max-width: $mobile){
            width: 60px;
            margin-left: 10px;
        }

    }

    &__question{
        background-color: $main_color;
        padding: 20px 35px;
        padding-right: 45px;
        border-radius: 10px;
        color: $font_light;
        position: relative;
        cursor: pointer;
        margin: 10px 0;

        .ico{
            position: absolute;
            right: 40px;
            transform: rotate(-90deg);
            @media(max-width: $small_screen){
                right: 15px;
            }
        }
    }

    .faq__answer{
        padding: 15px 50px;
    }


    .questions__wrapper{
        list-style-position: inside;
    }
    .open{
        .ico{
            transform: rotate(90deg);
        }
    }
}