.pagination{
    list-style: none;
    display: inline-block;
    @include centerX;
    margin-top: 40px;

    li{
        display: inline-block;
        margin: 0 5px;
    }
    a{
        color: $font_dark;
        font-size: $header_superSmall;
    }
    span{
        font-size: $header_superSmall;
        font-family: "Titillium";
    }
    .active{
        color: $sec_color;
    }

    .next, .prev{
        display: block;
        background-image: url(../img/ico/arrow.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 15px;
        width: 15px;

    }

    .next{
        transform: rotate(180deg);
    }

}